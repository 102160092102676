import React, { useState } from "react";
import prettier from "prettier";
import babel from "prettier/parser-babel";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

const prettierSettings = {
  parser: "json",
  plugins: [babel],
};

const isValid = (code) => {
  try {
    return code && prettier.check(code, prettierSettings);
  } catch {
    return false;
  }
};

const sourceToCsv = (sourceJson, { switched, released }) => {
  if (!isValid(sourceJson)) {
    return "";
  }

  const source = JSON.parse(sourceJson);
  const devices = source.LIPIdList.Devices.filter((d) => d.ID !== 1).map(
    (d) => `${released ? "q" : "p"},${d.ID},${d.Area?.Name} ${d.Name}`
  );
  const zones = source.LIPIdList.Zones.map(
    (d) => `${switched ? "s" : "d"},${d.ID},${d.Area?.Name} ${d.Name}`
  );
  return [...devices, ...zones].join("\n");
};

function App() {
  const [source, setSource] = useState("");
  const [released, setReleased] = useState(false);
  const [switched, setSwitched] = useState(false);
  const [recentPaste, setRecentPaste] = useState(false);

  const csv = sourceToCsv(source, { switched, released });

  const onValueChange = (code) => {
    if (recentPaste) {
      const filteredCode = code.replace(/Sent from my iPhone/, "");
      try {
        setSource(prettier.format(filteredCode, prettierSettings));
      } catch {
        setSource(code);
      }
      setRecentPaste(false);
    } else {
      setSource(code);
    }
  };

  const onClickCsv = ({ target }) => {
    target.setSelectionRange(0, target.value.length);
    navigator.clipboard.writeText(csv);
  };

  return (
    <div
      style={{
        padding: 10,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
        height: "95vh",
      }}
    >
      <div
        style={{
          border: "1px solid black",
          flex: 1,
          overflow: "auto",
        }}
        onPaste={() => setRecentPaste(true)}
      >
        <Editor
          placeholder="Paste Integration Report email here"
          value={source}
          onValueChange={onValueChange}
          highlight={(code) => highlight(code, languages.js)}
          padding={10}
          style={{
            minHeight: "100%",
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
          }}
        />
      </div>
      <div style={{ flex: 0 }}>
        <label style={{ marginRight: 15 }}>
          <input
            style={{ marginRight: 5 }}
            type="checkbox"
            checked={released}
            onChange={() => setReleased(!released)}
          />
          Use &quot;Push/Released&quot; instead of &quot;Push/Held&quot;
        </label>
        <label style={{ marginRight: 15 }}>
          <input
            style={{ marginRight: 5 }}
            type="checkbox"
            checked={switched}
            onChange={() => setSwitched(!switched)}
          />
          Use &quot;Switch&quot; instead of &quot;Dimmer&quot;
        </label>
      </div>
      <div
        style={{
          border: "1px solid black",
          flex: 1,
          overflow: "auto",
        }}
        onPaste={() => setRecentPaste(true)}
      >
        <Editor
          placeholder={"Invalid!"}
          value={csv}
          highlight={(code) => highlight(code, languages.js)}
          padding={10}
          onClick={onClickCsv}
          style={{
            minHeight: "100%",
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
          }}
        />
      </div>
    </div>
  );
}

export default App;
